import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'

import { useCollectionActionsContext } from 'context/CollectionContext'
import Button from 'components/atoms/buttons/Button'

const Container = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 15px;
  padding-left: 0;
`
const StyledButton = styled(Button)`
  padding: 0 !important;
  width: 50px;
  min-width: 50px;
  height: 50px;
`

const AddToCollectionMini = ({ itemIds }) => {
  const { addToCollection } = useCollectionActionsContext()

  return (
    <Tooltip title="Add to collection" placement="left" arrow>
      <Container>
        <StyledButton
          color="primary"
          className="double-border sharp primary-bg"
          aria-label="Add to collection"
          onClick={() => addToCollection(itemIds)}
        >
          <AddIcon />
        </StyledButton>
      </Container>
    </Tooltip>
  )
}

AddToCollectionMini.propTypes = {
  itemIds: PropTypes.array.isRequired,
}

export default memo(AddToCollectionMini)
