import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Swiper, Navigation } from 'swiper/js/swiper.esm.js'
import ReactIdSwiper from '@danteblitz/react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'

import Button from 'components/atoms/buttons/Button'
import breakpoints from 'helpers/breakpoints'

const Container = styled.div`
  &,
  .swiper-container {
    ${breakpoints.greaterThan('lg')`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      min-height: 1000px;
    `}
  }

  .swiper-nav-wrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    font-weight: ${props => props.theme.fontWeights.bold};
    transition: background-color ${props => props.theme.transitions.default},
      opacity ${props => props.theme.transitions.default};

    &.swiper-button-disabled {
      opacity: 0.4;
    }

    &.swiper-btn-prev {
      left: 5px;
      transform: rotate(-90deg) translate3d(-50%, 0, 0);
      transform-origin: top left;

      ${breakpoints.greaterThan('xl')`
        left: 40px;
      `}
    }
    &.swiper-btn-next {
      right: 5px;
      transform: rotate(-90deg) translate3d(-50%, 50%, 0);
      transform-origin: top left;

      .MuiButton-label {
        transform: rotate(-180deg);
      }

      ${breakpoints.greaterThan('xl')`
        right: 40px;
      `}
    }
  }
`

const ProductSwiper = ({ children, startIndex }) => {
  const params = {
    Swiper,
    autoHeight: true,
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: startIndex,
    navWrapper: true,
    shouldSwiperUpdate: true,
    rebuildOnUpdate: true,
    // speed: 600,
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    // eslint-disable-next-line react/display-name
    renderPrevButton: () => (
      <Button
        className="nav-btn sharp lg hidden-border swiper-nav-btn swiper-btn-prev"
        variant="outlined"
        aria-label="Previous"
      >
        Prev
      </Button>
    ),
    // eslint-disable-next-line react/display-name
    renderNextButton: () => (
      <Button
        className="nav-btn sharp lg hidden-border swiper-nav-btn swiper-btn-next"
        variant="outlined"
        aria-label="Next"
      >
        Next
      </Button>
    ),
  }

  return (
    <Container>
      <ReactIdSwiper {...params}>{children}</ReactIdSwiper>
    </Container>
  )
}

ProductSwiper.propTypes = {
  children: PropTypes.node.isRequired,
  startIndex: PropTypes.number.isRequired,
}

export default memo(ProductSwiper)
