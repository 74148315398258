import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Box from 'components/atoms/Box'

const Frame = styled(Box)`
  background: black;
`
const Mount = styled(Box)`
  background: white;
`

const PictureFrame = ({ image, ...props }) => {
  return (
    <Frame {...props} p={{ xs: '15px' }}>
      <Mount p={{ xs: 3 }}>
        <Img fluid={image.fluid} />
      </Mount>
    </Frame>
  )
}

PictureFrame.propTypes = {
  image: PropTypes.object.isRequired,
}

export default memo(PictureFrame)
