import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'

import Section from 'components/atoms/layout/Section'
import SectionJoin from 'components/atoms/dividers/SectionJoin'
import BackgroundHeading from 'components/atoms/headings/BackgroundHeading'
import Heading from 'components/atoms/headings/Heading'
import HeadingDivider from 'components/atoms/dividers/HeadingDivider'

import Product from 'components/molecules/cards/Product'
import OneLargeOneSmall from 'components/molecules/grid-layouts/OneLargeOneSmall'
import OneLargeTwoSmall from 'components/molecules/grid-layouts/OneLargeTwoSmall'
import TwoEqual from 'components/molecules/grid-layouts/TwoEqual'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledBackgroundHeading = styled(BackgroundHeading)`
  transform: translateY(80px);
`

const Products = ({ productLayout, collectionName }) => {
  return (
    <Section
      bg="greyLight"
      pt={{ xs: '0px', md: 4, lg: 5 }}
      pb={{
        xs: 8,
        md: 6,
        lg: 8,
        xl: 15,
      }}
    >
      <StyledBackgroundHeading>{collectionName}</StyledBackgroundHeading>
      <Container maxWidth="xl" className="foreground">
        {productLayout.map((item, index) => {
          switch (item.model.apiKey) {
            case 'category_heading':
              return (
                <div key={`${item.model.apiKey}-${index}`}>
                  <TextPlx>
                    <Heading
                      as="h2"
                      headingSize="h2"
                      pt={{ xs: 8, xl: 15 }}
                      textAlign={{ xs: 'center', lg: 'left' }}
                    >
                      {item.heading}
                    </Heading>
                  </TextPlx>
                  <TextPlx>
                    <HeadingDivider
                      mt={1}
                      mb={{ xs: 3, md: 4, xl: 5 }}
                      mx={{ xs: 'auto', lg: '0px' }}
                    />
                  </TextPlx>
                </div>
              )
            case 'product_layout_1_large_1_small':
              return (
                <OneLargeOneSmall
                  key={`${item.model.apiKey}-${index}`}
                  isLargeFirst={item.isLargeFirst}
                  large={<Product product={item.productLargeImage} />}
                  small={<Product product={item.productSmallImage} />}
                />
              )
            case 'product_layout_1_large_2_small':
              return (
                <OneLargeTwoSmall
                  key={`${item.model.apiKey}-${index}`}
                  isLargeFirst={item.isLargeFirst}
                  large={<Product product={item.productLargeImage} />}
                  small1={<Product product={item.productsSmallImages[0]} />}
                  small2={<Product product={item.productsSmallImages[1]} />}
                />
              )
            case 'product_layout_2_equal':
              return (
                <TwoEqual
                  key={`${item.model.apiKey}-${index}`}
                  first={<Product product={item.products[0]} />}
                  last={<Product product={item.products[1]} />}
                />
              )
          }
        })}
      </Container>
      <SectionJoin />
    </Section>
  )
}

Products.propTypes = {
  productLayout: PropTypes.array.isRequired,
  collectionName: PropTypes.string.isRequired,
}

export default Products
