import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import ContainerGrid from './ContainerGrid'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledContainerGrid = styled(ContainerGrid)`
  .plx {
    height: 100%;
  }
`

const TwoEqual = ({ first, last }) => {
  return (
    <StyledContainerGrid>
      <Grid item xs={12} md={6}>
        <TextPlx reverse className="plx">
          {first}
        </TextPlx>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextPlx className="plx">{last}</TextPlx>
      </Grid>
    </StyledContainerGrid>
  )
}

TwoEqual.propTypes = {
  first: PropTypes.node.isRequired,
  last: PropTypes.node.isRequired,
}

export default memo(TwoEqual)
