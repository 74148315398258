import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Tooltip from '@material-ui/core/Tooltip'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import { InView } from 'react-intersection-observer'
import AddIcon from '@material-ui/icons/Add'

import breakpoints from 'helpers/breakpoints'
import {
  useSlidesOverlayContext,
  useSlidesOverlayActionsContext,
} from 'context/SlidesOverlayContext'
import { useSwatchDrawerActionsContext } from 'context/SwatchDrawerContext'
import { useGlobalActionsContext } from 'context/GlobalContext'

import SectionHeading from 'components/atoms/headings/SectionHeading'
import ProductSwiper from 'components/molecules/swipers/ProductSwiper'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Button from 'components/atoms/buttons/Button'
import SwatchDrawer from './SwatchDrawer'

import ExternalButtonLink from 'components/atoms/buttons/ExternalButtonLink'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    top: 0;
  }
`

const ContainerGrid = styled(Grid)`
  ${breakpoints.greaterThan('lg')`
    align-items: stretch;
    height: 100vh;
    min-height: 1000px;
  `}
  font-size: 1.6rem;

  .content-grid {
    order: 2;
  }
  .image-grid {
    order: 1;
  }

  ${breakpoints.greaterThan('lg')`
    .content-grid {
        order: 1;

    }
    .image-grid {
        order: 2;

    }
  `}

  .cta {
    width: 60%;
  }
`
const ImageGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.greyLight};
  position: relative;
`

const ImageFlex = styled(Flex)`
  // ${breakpoints.greaterThan('lg')`
  //   width: 80%;
  //   margin: 0 auto;
  // `}
  ${breakpoints.greaterThan('lg')`
    position: absolute !important;
    top: 10%;
    right: 12%;
    bottom: 10%;
    left: 12%;
    width: auto;
    height: auto;    
  `}
  ${breakpoints.greaterThan('xl')`
    top: 14%;
    right: 20%;
    bottom: 14%;
    left: 20%; 
  `}
`

const StyledImage = styled(Img)`
  width: 100%;
  // border: 50px solid white;

  ${breakpoints.greaterThan('lg')`
    position: absolute;
    width: 80%;
    height: 80%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      object-fit: contain !important;
    }
  `}
`
const SwatchImage = styled(Img)`
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  border: 4px solid white;
  cursor: pointer;
  overflowL hidden;
  img {
    transform: scale(1);
    transition: opacity 500ms ease 0s, transform ${(props) =>
      props.theme.transitions.medium} !important;
  }
  

  &:hover {
    img {
      transform: scale(1.5);
    }
    
  }
`

const InfoButton = styled(Flex)`
  padding: 0;
  width: 64px;
  height: 64px;
  border: 4px solid white;
  border-radius: 50%;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: background-color ${(props) => props.theme.transitions.medium},
    color ${(props) => props.theme.transitions.medium};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
  }
`

const ProductDetail = () => {
  const { openSwatchDrawer } = useSwatchDrawerActionsContext()
  const { handleAddToCollection } = useSlidesOverlayActionsContext()
  const {
    slides,
    slidesType,
    brochureUrl,
    open,
    startIndex,
  } = useSlidesOverlayContext()
  const { updateSolidNav } = useGlobalActionsContext()

  if (slides && slides.length > 0 && slidesType === 'products') {
    return (
      <>
        <StyledDrawer open={open} anchor="bottom">
          <InView
            className="in-view"
            as="div"
            onChange={(inView) => {
              updateSolidNav(!inView)
            }}
          />
          <ProductSwiper startIndex={startIndex}>
            {slides.map((product, index) => {
              const isPortrait =
                product &&
                product.productImage &&
                product.productImage.fluid &&
                product.productImage.fluid.aspectRatio < 1.2
              return (
                <div key={`detail-product-${product.originalId}-${index}`}>
                  <ContainerGrid container spacing={0}>
                    <Grid item xs={12} lg={5} xl={6} className="content-grid">
                      <Flex full flexDirection="column">
                        <Box
                          my={'auto'}
                          px={{ lg: 3, xl: 12 }}
                          py={{ xs: 7, lg: '0px' }}
                          textAlign="center"
                        >
                          <Container maxWidth="xl">
                            <SectionHeading containerProps={{ mb: 4 }}>
                              {product.items.productName}
                            </SectionHeading>
                            <span className="caps">
                              {product.items.dimensions}
                            </span>
                            {product.items.price && (
                              <Box fontWeight="bold" mt={1}>
                                &euro;{product.items.price}
                              </Box>
                            )}

                            <Flex center mt={{ xs: 3 }}>
                              <Flex flexDirection="column" alignItems="stretch">
                                <Box flex={1}>
                                  <Button
                                    color="primary"
                                    className="double-border sharp primary-bg xl"
                                    startIcon={<AddIcon />}
                                    fullWidth
                                    onClick={() =>
                                      handleAddToCollection(
                                        product.collectionItems,
                                        index
                                      )
                                    }
                                  >
                                    Add To Collection
                                  </Button>
                                </Box>
                                <Box flex={1} mt={1}>
                                  <ExternalButtonLink
                                    href={brochureUrl}
                                    className="double-border sharp dark-bg xl"
                                    fullWidth
                                  >
                                    Download Brochure
                                  </ExternalButtonLink>
                                </Box>
                              </Flex>
                            </Flex>
                          </Container>
                        </Box>
                      </Flex>
                    </Grid>
                    <ImageGrid
                      item
                      xs={12}
                      lg={7}
                      xl={6}
                      className="image-grid"
                    >
                      <Flex
                        px={{ xs: 2, lg: '0px' }}
                        pt={{ xs: 10, lg: '0px' }}
                        pb={{ xs: 4, lg: '0px' }}
                      >
                        <ImageFlex
                          full
                          center
                          flexDirection="column"
                          mt={{ xs: 1, lg: '0px' }}
                        >
                          <Flex
                            center
                            bg="white"
                            flex={{ lg: 1 }}
                            position="relative"
                            width={{
                              xs: '100%',
                              sm: isPortrait ? '40%' : '80%',
                              lg: '100%',
                            }}
                            px={{
                              xs: isPortrait ? 8 : 2,
                              sm: isPortrait ? 8 : 4,
                              lg: '0px',
                            }}
                            py={{
                              xs: isPortrait ? 2 : 8,
                              sm: isPortrait ? 4 : 8,
                              lg: '0px',
                            }}
                            mx={{ xs: 2, lg: '0px' }}
                          >
                            <StyledImage fluid={product.productImage.fluid} />
                          </Flex>
                          <Flex
                            mt={4}
                            fullWidth
                            flexWrap="wrap"
                            alignItems="flex-start"
                            justifyContent="center"
                          >
                            {product.materials.map((swatch) => {
                              return (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  flexDirection="column"
                                  key={swatch.originalId}
                                  mx="4px"
                                  mb={0}
                                  onClick={() =>
                                    openSwatchDrawer(product.materials)
                                  }
                                >
                                  <Tooltip
                                    title={swatch.label}
                                    placement="top"
                                    arrow
                                  >
                                    <div>
                                      <SwatchImage
                                        fixed={swatch.material.image.fixed}
                                      />
                                    </div>
                                  </Tooltip>
                                </Flex>
                              )
                            })}
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              flexDirection="column"
                              mx="4px"
                              mb={0}
                              onClick={() =>
                                openSwatchDrawer(product.materials)
                              }
                            >
                              <Tooltip
                                title="View Details"
                                placement="top"
                                arrow
                              >
                                <div>
                                  <InfoButton
                                    variant="contained"
                                    color="primary"
                                    center
                                    onClick={() =>
                                      openSwatchDrawer(product.materials)
                                    }
                                  >
                                    <FormatListBulletedIcon
                                      style={{ fontSize: 24 }}
                                    />
                                  </InfoButton>
                                </div>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </ImageFlex>
                      </Flex>
                    </ImageGrid>
                  </ContainerGrid>
                </div>
              )
            })}
          </ProductSwiper>
        </StyledDrawer>
        <SwatchDrawer />
      </>
    )
  }
  return null
}

export default ProductDetail
