import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Swiper, Autoplay } from 'swiper/js/swiper.esm.js'
import ReactIdSwiper from '@danteblitz/react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'

const Container = styled.div`
  &,
  .swiper-container {
    position: relative;
    width: 100%;
  }

  .swiper-wrapper {
    width: 100%;
  }

  .swiper-slide {
    // width: 100% !important;
  }
`

const LineItemSwiper = ({ children }) => {
  const params = {
    Swiper,
    autoHeight: true,
    modules: [Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0,
    // cache: false,
    cssMode: true,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    // navWrapper: true,
    // shouldSwiperUpdate: true,
    // rebuildOnUpdate: true,
    // speed: 600,
    // fadeEffect: {
    //   crossFade: true,
    // },
    // navigation: {
    //   nextEl: '.swiper-btn-next',
    //   prevEl: '.swiper-btn-prev',
    // },
    // eslint-disable-next-line react/display-name
    // renderPrevButton: () => (
    //   <Button
    //     className="nav-btn sharp lg hidden-border swiper-nav-btn swiper-btn-prev"
    //     variant="outlined"
    //     aria-label="Previous"
    //   >
    //     Prev
    //   </Button>
    // ),
    // // eslint-disable-next-line react/display-name
    // renderNextButton: () => (
    //   <Button
    //     className="nav-btn sharp lg hidden-border swiper-nav-btn swiper-btn-next"
    //     variant="outlined"
    //     aria-label="Next"
    //   >
    //     Next
    //   </Button>
    // ),
  }

  return (
    <Container>
      <ReactIdSwiper {...params}>{children}</ReactIdSwiper>
    </Container>
  )
}

LineItemSwiper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(LineItemSwiper)
