import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import slugify from '@sindresorhus/slugify'
import traverse from 'traverse'

import { useSlidesOverlayActionsContext } from 'context/SlidesOverlayContext'
import createCollectionItemsArray from 'helpers/createCollectionItemsArray'

import Layout from 'components/organisms/global/Layout'
import Flex from 'components/atoms/Flex'
import ImageHero from 'components/molecules/hero/ImageHero'
import Heading from 'components/atoms/headings/Heading'
import HeadingDivider from 'components/atoms/dividers/HeadingDivider'
import IntroText from 'components/organisms/sections/IntroText'
import Products from 'components/organisms/_page-specific/collection/Products'
import Art from 'components/organisms/_page-specific/collection/Art'
import DownloadSection from 'components/organisms/sections/DownloadSection'
import ProductDetail from 'components/organisms/_page-specific/collection/ProductDetail'
import Quotes from 'components/organisms/sections/Quotes'

const CollectionDetail = ({ data }) => {
  const [newProductLayout, setNewProductLayout] = useState([])

  const {
    handleSetSlides,
    handleSetSlidesType,
    handleSetBrochureUrl,
  } = useSlidesOverlayActionsContext()
  const collection = data.datoCmsCollection
  const art = data.datoCmsArtPhotography
  const quotes = data.allDatoCmsTestimonial.edges

  const metaObj = {
    title: `${collection.collectionName} Furniture Collection`,
    description: collection.summary,
    image: {
      src: collection.metaImage.fixed.src,
      width: collection.metaImage.fixed.width,
      height: collection.metaImage.fixed.height,
    },
  }

  useEffect(() => {
    let navItems = []
    let products = []

    collection.productLayout.map((item) => {
      if (
        item.model &&
        item.model.apiKey &&
        item.model.apiKey === 'category_heading'
      ) {
        // get all headings for top navigation
        navItems.push({
          name: item.heading,
          slug: slugify(item.heading),
        })
      } else {
        // get all products for product detail swiper
        switch (item.model.apiKey) {
          case 'product_layout_1_large_1_small': {
            if (item.isLargeFirst) {
              products.push(item.productLargeImage)
              products.push(item.productSmallImage)
            } else {
              products.push(item.productSmallImage)
              products.push(item.productLargeImage)
            }
            break
          }
          case 'product_layout_1_large_2_small': {
            if (item.isLargeFirst) {
              products.push(item.productLargeImage)
              item.productsSmallImages.map((small) => {
                products.push(small)
              })
            } else {
              item.productsSmallImages.map((small) => {
                products.push(small)
              })
              products.push(item.productLargeImage)
            }
            break
          }
          case 'product_layout_2_equal': {
            item.products.map((p) => {
              products.push(p)
            })
            break
          }
        }
      }
    })

    // get each product line item for its own product slide
    let productLineItems = []
    products.map((productLine) => {
      if (productLine.items.length > 0) {
        const collectionItems = createCollectionItemsArray(
          productLine.items,
          productLine.originalId
        )
        productLine.items.map((productLineItem) => {
          productLineItems.push({
            ...productLine,
            items: productLineItem,
            collectionItems: collectionItems,
          })
        })
      } else {
        productLineItems.push(productLine)
      }
    })

    handleSetSlides(productLineItems)

    handleSetSlidesType('products')
    handleSetBrochureUrl(collection.downloadBrochurePdfFile.url)

    // add the slide ids back into each product
    let tmpNewProductLayout = [...collection.productLayout]
    productLineItems.map((item, index) => {
      traverse(tmpNewProductLayout).map(function (x) {
        if (x && x.model && x.model.apiKey && x.model.apiKey == 'product') {
          traverse(x.items).forEach(function (y) {
            if (y && y.originalId && y.originalId === item.items.originalId) {
              this.update({ ...y, slideIndex: index })
            }
          })
        }
      })
    })
    setNewProductLayout(tmpNewProductLayout)
  }, [])

  return (
    <Layout
      metadata={metaObj}
      headerProps={{
        navImage: collection.mainNavigationImage,
      }}
    >
      <ImageHero
        images={collection.heroSectionBackgroundImage}
        explore="Explore the range"
      >
        <Flex center full flexDirection="column">
          <Heading as="h1" headingSize="jumbo" color="white">
            {collection.collectionName}
          </Heading>
          {collection.heroSectionSubtitle && (
            <>
              <HeadingDivider mt={0} mb={3} />
              <Heading
                as="h3"
                headingSize="h6"
                fontFamily="sans"
                fontWeight="regular"
                color="white"
              >
                {collection.heroSectionSubtitle}
              </Heading>
            </>
          )}
        </Flex>
      </ImageHero>

      <IntroText
        text={collection.summary}
        pb={{ xs: 3, sm: 4 }}
        style={{ zIndex: 20 }}
      />
      {newProductLayout.length > 0 && (
        <Products
          productLayout={newProductLayout}
          collectionName={collection.collectionName}
        />
      )}

      <Art art={art} />
      <Quotes
        bgImage={collection.testimonialsSectionBackgroundImage}
        quotes={quotes}
      />
      <DownloadSection
        heading={collection.downloadBrochureHeading}
        text={collection.downloadBrochureTextNode.childMarkdownRemark.html}
        fileUrl={collection.downloadBrochurePdfFile.url}
        buttonText={collection.downloadBrochureButtonText}
      />
      <ProductDetail />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    datoCmsCollection(slug: { eq: $slug }) {
      metaImage: heroSectionBackgroundImage {
        fixed(
          width: 1024
          height: 512
          imgixParams: {
            fit: "crop"
            w: "1024"
            h: "512"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          width
          height
          src
        }
      }
      slug
      productLayout {
        ... on DatoCmsCategoryHeading {
          heading
          model {
            apiKey
          }
        }
        ... on DatoCmsProductLayout1Large2Small {
          model {
            apiKey
          }
          isLargeFirst
          productLargeImage {
            ...Product
          }
          productsSmallImages {
            ...Product
          }
        }
        ... on DatoCmsProductLayout1Large1Small {
          model {
            apiKey
          }
          isLargeFirst
          productLargeImage {
            ...Product
          }
          productSmallImage {
            ...Product
          }
        }
        ... on DatoCmsProductLayout2Equal {
          model {
            apiKey
          }
          products {
            ...Product
          }
        }
      }
      originalId
      heroSectionSubtitle
      downloadBrochureHeading
      downloadBrochureButtonText
      downloadBrochureTextNode {
        childMarkdownRemark {
          html
        }
      }
      downloadBrochurePdfFile {
        url
      }
      collectionName
      summary
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      heroSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      testimonialsSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
    datoCmsArtPhotography {
      heading
      text
      portraitImage {
        fluid(
          maxWidth: 400
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "400"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      landscapeImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "4:3"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    allDatoCmsTestimonial(
      filter: { collections: { elemMatch: { slug: { eq: $slug } } } }
      sort: { order: DESC, fields: meta___publishedAt }
      limit: 5
    ) {
      edges {
        node {
          personName
          personOrganisation
          originalId
          testimonial
          personImage {
            fixed(
              width: 80
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                ar: "1:1"
                fit: "crop"
                sat: -100
                w: "80"
              }
            ) {
              src
            }
          }
        }
      }
    }
  }
`

CollectionDetail.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CollectionDetail
