import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SectionWaveBg from 'components/atoms/layout/SectionWaveBg'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import Heading from 'components/atoms/headings/Heading'
import PictureFrame from 'components/atoms/media/PictureFrame'
import TextPlx from 'components/atoms/plx/TextPlx'

const PictureGrid = styled(Grid)`
  align-items: center;
`

const Art = ({ art }) => {
  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'), {
    defaultMatches: true,
  })

  return (
    <SectionWaveBg
      bg="darkBg"
      color="white"
      pb={{
        xs: 8,
        lg: 10,
        xl: 17,
      }}
      pt={{
        xs: 12,
        lg: 13,
        xl: 17,
      }}
    >
      <Container maxWidth="lg" className="foreground">
        <TextPlx>
          <SectionHeading color="white">{art.heading}</SectionHeading>
        </TextPlx>
        <TextPlx>
          <Heading
            $as="div"
            headingSize="h3"
            color="white"
            lineHeight="1.4"
            mb={{ xs: 6 }}
          >
            {art.text}
          </Heading>
        </TextPlx>
      </Container>

      <Container maxWidth="xl" className="foreground">
        <PictureGrid container spacing={isMobile ? 2 : 6}>
          <Grid item xs={12} md={5}>
            <TextPlx reverse>
              <PictureFrame image={art.portraitImage} />
            </TextPlx>
          </Grid>
          <Grid item xs={12} md={7}>
            <TextPlx>
              <PictureFrame image={art.landscapeImage} />
            </TextPlx>
          </Grid>
        </PictureGrid>
      </Container>
    </SectionWaveBg>
  )
}

Art.propTypes = {
  art: PropTypes.object.isRequired,
}

export default memo(Art)
