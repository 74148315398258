import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { useSlidesOverlayActionsContext } from 'context/SlidesOverlayContext'
import createCollectionItemsArray from 'helpers/createCollectionItemsArray'

import AddToCollectionMini from 'components/atoms/buttons/AddToCollectionMini'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import SmallText from 'components/atoms/text/SmallText'
import HoverEffect from 'components/atoms/media/HoverEffect'
import LineItemSwiper from 'components/molecules/swipers/LineItemSwiper'

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
`

const StyledHoverEffect = styled(HoverEffect)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const Container = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;

  .gatsby-image-wrapper {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`
const ProductName = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
`
const Price = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

const LineItem = styled(Box)`
  position: relative;
  z-index: 10;
  transition: color ${(props) => props.theme.transitions.default};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const Product = ({ product }) => {
  const { openSlidesOverlay } = useSlidesOverlayActionsContext()

  const { thumbnail, items } = product
  const itemIds = createCollectionItemsArray(items, product.originalId)

  return (
    <CardContainer>
      <StyledHoverEffect
        onClick={() => openSlidesOverlay(parseInt(items[0].slideIndex))}
      >
        <Container bg="white">
          <Flex full center flexDirection="column">
            <Flex width={1} flex={1} center full>
              <Box
                px={{ xs: 2, sm: 4, md: 6, lg: 8 }}
                pt={{ xs: 4, md: 6, lg: 8 }}
                width={1}
              >
                <Img fluid={thumbnail.fluid} />
              </Box>
            </Flex>

            {items.length > 1 ? (
              <LineItemSwiper>
                {items.map((item) => (
                  <div key={item.originalId}>
                    <LineItem textAlign="center" py={3} px={2}>
                      <ProductName>{item.productName}</ProductName>
                      <SmallText>{item.dimensions}</SmallText>
                      {item.price && <Price>&euro;{item.price}</Price>}
                    </LineItem>
                  </div>
                ))}
              </LineItemSwiper>
            ) : (
              <Flex fullWidth justifyContent="space-evenly" py={{ xs: 3 }}>
                {items.map((item) => {
                  return (
                    <LineItem
                      key={item.originalId}
                      textAlign="center"
                      px={2}
                      onClick={(e) => {
                        openSlidesOverlay(parseInt(item.slideIndex))
                        e.stopPropagation()
                      }}
                    >
                      <ProductName>{item.productName}</ProductName>
                      <SmallText>{item.dimensions}</SmallText>
                      {item.price && <Price>&euro;{item.price}</Price>}
                    </LineItem>
                  )
                })}
              </Flex>
            )}
          </Flex>
        </Container>
      </StyledHoverEffect>
      <AddToCollectionMini itemIds={itemIds} />
    </CardContainer>
  )
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
}

export default memo(Product)
