import React, {memo} from 'react'
import styled from 'styled-components'
import { useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Grid from '@material-ui/core/Grid'
import Box from 'components/atoms/Box'

const ContainerGrid = styled(Grid)`
  align-items: stretch;

  .first {
    order: 1;
  }
  .last {
    order: 2;
  }
`

const OneLargeOneSmall = props => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  })

  return (
    <Box mb={{ xs: 1, xl: 3 }}>
      <ContainerGrid container spacing={isMobile ? 2 : 6} {...props} />
    </Box>
  )
}

export default memo(OneLargeOneSmall)
