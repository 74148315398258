import React from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import Img from 'gatsby-image'
import CloseIcon from '@material-ui/icons/Close'

import {
  useSwatchDrawerContext,
  useSwatchDrawerActionsContext,
} from 'context/SwatchDrawerContext'
import breakpoints from 'helpers/breakpoints'

import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import SmallText from 'components/atoms/text/SmallText'
import Heading from 'components/atoms/headings/Heading'
import Button from 'components/atoms/buttons/Button'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    left: 0;

    ${breakpoints.greaterThan('sm')`
        left: auto;
    `}
  }
`

const SwatchImage = styled(Img)`
  border-radius: 50%;
  border: 4px solid ${props => props.theme.colors.greyLight};

  &,
  img {
    width: 60px !important;
    min-width: 60px;
    height: 60px !important;
    display: block;
  }
`

const CloseButton = styled(Button)`
  border-color: ${props => props.theme.colors.textDefault};
  .outer-label {
    color: ${props => props.theme.colors.textDefault};
  }
`
const SwatchLabel = styled(SmallText)`
  letter-spacing: 0.1rem;
  font-weight: ${props => props.theme.fontWeights.semiBold};
`

const SwatchDrawer = () => {
  const { swatches, open } = useSwatchDrawerContext()
  const { closeSwatchDrawer } = useSwatchDrawerActionsContext()

  const xPadding = { xs: 4, sm: 6, lg: 7, xl: 10 }

  return (
    <StyledDrawer
      open={open}
      onClose={() => closeSwatchDrawer()}
      anchor="right"
      style={{ zIndex: 1400 }}
    >
      <Flex full flexDirection="column">
        <Box alignSelf="flex-start" mt={4} px={xPadding}>
          <CloseButton
            className="md round outer-label outline-dark caps menu-btn"
            outerLabel="Close"
            onClick={() => closeSwatchDrawer()}
            variant="outlined"
          >
            <CloseIcon className="close" />
          </CloseButton>
        </Box>

        <Box px={xPadding} my="auto">
          <Heading $as="h2" headingSize="h3" mb={6}>
            Materials and options
          </Heading>
          {swatches && (
            <>
              {swatches.map(swatch => {
                return (
                  <Flex
                    verticalCenter
                    fullWidth
                    my={2}
                    key={`drawer-${swatch.originalId}`}
                  >
                    <SwatchImage fixed={swatch.material.image.fixed} />

                    <SwatchLabel
                      fontWeight="semiBold"
                      ml={2}
                      lineHeight="1.2"
                      textAlign="left"
                    >
                      {swatch.label}
                    </SwatchLabel>
                  </Flex>
                )
              })}
            </>
          )}
        </Box>
      </Flex>
    </StyledDrawer>
  )
}

export default SwatchDrawer
