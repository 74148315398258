import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import ContainerGrid from './ContainerGrid'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledContainerGrid = styled(ContainerGrid)`
  .plx {
    height: 100%;
  }
`

const OneLargeOneSmall = ({ large, small, isLargeFirst }) => {
  return (
    <StyledContainerGrid>
      <Grid
        item
        xs={12}
        md={6}
        lg={7}
        className={isLargeFirst ? 'first' : 'last'}
      >
        <TextPlx reverse={isLargeFirst ? true : false} className="plx">
          {large}
        </TextPlx>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        className={isLargeFirst ? 'last' : 'first'}
      >
        <TextPlx reverse={isLargeFirst ? false : true} className="plx">
          {small}
        </TextPlx>
      </Grid>
    </StyledContainerGrid>
  )
}

OneLargeOneSmall.propTypes = {
  large: PropTypes.node.isRequired,
  small: PropTypes.node.isRequired,
  isLargeFirst: PropTypes.bool,
}

export default memo(OneLargeOneSmall)
