import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

import breakpoints from 'helpers/breakpoints'

import ContainerGrid from './ContainerGrid'
import Box from 'components/atoms/Box'
import TextPlx from 'components/atoms/plx/TextPlx'

const VisibleLgUp = styled(Grid)`
  ${breakpoints.lessThan('lg')`
      display: none;
    `}
`
const VisibleLgDown = styled(Grid)`
  ${breakpoints.greaterThan('lg')`
      display: none;
    `}
`
const StyledContainerGrid = styled(ContainerGrid)`
  .plx {
    height: 100%;
  }
`

const TwoEqual = ({ large, small1, small2, isLargeFirst }) => {
  return (
    <StyledContainerGrid>
      <Grid item xs={12} lg={8} className={isLargeFirst ? 'first' : 'last'}>
        <TextPlx reverse={isLargeFirst ? true : false} className="plx">
          {large}
        </TextPlx>
      </Grid>

      <VisibleLgDown
        item
        xs={12}
        md={6}
        className={isLargeFirst ? 'last' : 'first'}
      >
        <TextPlx reverse={isLargeFirst ? false : true} className="plx">
          {small1}
        </TextPlx>
      </VisibleLgDown>
      <VisibleLgDown
        item
        xs={12}
        md={6}
        className={isLargeFirst ? 'last' : 'first'}
      >
        <TextPlx reverse={isLargeFirst ? false : true} className="plx">
          {small2}
        </TextPlx>
      </VisibleLgDown>

      <VisibleLgUp
        item
        xs={12}
        md={6}
        lg={4}
        className={isLargeFirst ? 'last' : 'first'}
      >
        <Box>
          <Box mb={{ xs: 2, xl: 6 }}>
            {' '}
            <TextPlx reverse={isLargeFirst ? false : true} className="plx">
              {small1}
            </TextPlx>
          </Box>
          <Box mt={{ xs: 2, xl: 6 }}>
            {' '}
            <TextPlx reverse={isLargeFirst ? false : true} className="plx">
              {small2}
            </TextPlx>
          </Box>
        </Box>
      </VisibleLgUp>
    </StyledContainerGrid>
  )
}

TwoEqual.propTypes = {
  large: PropTypes.node.isRequired,
  small1: PropTypes.node.isRequired,
  small2: PropTypes.node.isRequired,
  isLargeFirst: PropTypes.bool,
}

export default memo(TwoEqual)
